import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import classNames from 'classnames';

import Analytics from '@web-solutions/module-analytics';
import { tm } from '@web-solutions/module-localization';

import { remoteConfigSelector } from 'core/store/remote-config/selectors';
import { PlansLayoutType, } from 'core/payment/constants';

import { Title, Section } from 'core/ui-elements';
import Swiper from 'core/ui-elements/swiper';

import TrialReminder from 'core/payment/components/trial-reminder';
import PaymentDescription from 'core/payment/components/payment-description';

import FixedButton from '../fixed-button';
import InjectedPaymentMethodSwitch from '../injected-payment-method/components/switch';

import { PaymentSafe } from '../../containers/payment-safe';

import PolicyCheckbox from './policy-check-box';
import Item from './item';

import classes from './style.module.scss';

const Plans = ({
  activeProduct,
  products,
  showTerms = true,
  showButton = true,
  onProductClick,
  onProductFocus,
  onButtonClick,
  onPayPalSubmit,
}) => {
  const plansSectionId = 'plans';
  const productsListId = 'products-list';

  const {
    policyLinks,
    policyCheckboxChecked,
    buttonsConfigs,
    productsTitle,
    showTermsAtPlans,
    showPaymentSafeAtPlans,
    plansType,
    isInjectedPaymentMethodOnPage,
    isTrialPeriodReminder,
  } = useSelector(remoteConfigSelector);

  const handleItemClick = useCallback(
    (selectedProduct) => {
      Analytics.trackEvent('product', 'selected', { selectedProductId: selectedProduct.id });
      onProductClick(selectedProduct);
    },
    [onProductClick],
  );

  const handleItemFocus = useCallback(
    (selectedProduct) => {
      Analytics.trackEvent('product', 'selected', { selectedProductId: selectedProduct.id });
      onProductFocus(selectedProduct);
    },
    [onProductFocus],
  );

  const handleFixedButtonClick = (type, isVisible) => {
    onButtonClick(type, isVisible);
  };

  const renderItems = useCallback(
    () => products.map((product) => (
      <Item
        key={product.id}
        isActive={activeProduct.id === product.id}
        onClick={handleItemClick}
        product={product}
      />
    )),
    [products, activeProduct.id, handleItemClick],
  );

  const handleSwipe = (index) => {
    handleItemFocus(products[index]);
  }

  const title = tm(productsTitle);

  const classContainer = classNames(classes.container, { [classes.gap]: plansType === PlansLayoutType.SLIDER && title });

  return (
    <Section className={classNames(classes.wrap, { [classes.fullScreen]: plansType === PlansLayoutType.SLIDER })} id={plansSectionId}>
      {title && <Title level="h2">{tm(productsTitle)}</Title>}
      {plansType === PlansLayoutType.SLIDER ?
        <div className={classContainer}>
          <Swiper
            id={productsListId}
            slides={renderItems()}
            slidesPerView={1.35}
            initialSlide={products.findIndex((product) => activeProduct.id === product.id)}
            onSwipe={handleSwipe}
            className={classes.slider}
          />
        </div>
        :
        <div className={classContainer}>
          <ul className={classes.productsList} id={productsListId}>{renderItems()}</ul>
        </div>
      }

      {
        isInjectedPaymentMethodOnPage && (
          <InjectedPaymentMethodSwitch />
        )
      }

      {
        (isTrialPeriodReminder && !activeProduct.isOneTimePurchase) && <TrialReminder className={classes.reminder} />
      }

      {showButton &&
        <FixedButton
          activeProduct={activeProduct}
          buttonsConfigs={buttonsConfigs}
          onClick={handleFixedButtonClick}
          id={productsListId}
          onPayPalSubmit={onPayPalSubmit}
          onCardClick={onButtonClick}
        />
      }

      {
        showTerms
          ? showTermsAtPlans
            ? (
              <PaymentDescription
                activeProduct={activeProduct}
                isShort
              />
            )
            : (
              <PolicyCheckbox policyLinks={policyLinks} checkboxChecked={policyCheckboxChecked} />
            )
          : null
      }

      {showPaymentSafeAtPlans ? <PaymentSafe isModal={false} /> : null}

    </Section>
  );
};

Plans.propTypes = {
  products: PropTypes.array.isRequired,
  activeProduct: PropTypes.object.isRequired,
  showTerms: PropTypes.bool,
  showButton: PropTypes.bool,
  onProductClick: PropTypes.func.isRequired,
  onProductFocus: PropTypes.func.isRequired,
  onButtonClick: PropTypes.func.isRequired,
  onApplePaySubmit: PropTypes.func,
  onPayPalSubmit: PropTypes.func
};

export default Plans;
