import i18next from 'i18next';

import * as TYPES from './types';

export const initialState = {
  authorized: false,
  linkAuthResult: null,

  email: '',

  name: '',
  gender: null,
  age: null,
  relStatus: null,

  handImg: null,
  handImgDims: null,
  handKeyPoints: null,
  handLines: null,
  handResults: null,

  birthDate: null, // YYYY-MM-DD
  partnerBirthDate: null, // YYYY-MM-DD
  birthTime: null, // HH:mm
  birthPlace: null, // { label: string, lat: string, lon: string }
  crushZodiac: null,

  color: null, // { color: string, text: string }
  element: null, // { image: obj, text: string }
  futureGoals: null, // [{ icon: string, text: string}]

  direction: null,
  goals: null,
  palmReport: null,

  lang: i18next.language,

  is_unsubscriber: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.SET_AUTHORIZED:
      return {
        ...state,
        authorized: true,
      };

    case TYPES.SET_LINK_AUTH_RESULT:
      return {
        ...state,
        linkAuthResult: action.payload,
      };

    case TYPES.SET_EMAIL:
      return {
        ...state,
        email: action.payload || initialState.email,
      };


    case TYPES.SET_NAME:
      return {
        ...state,
        name: action.payload,
      };

    case TYPES.SET_GENDER:
      return {
        ...state,
        gender: action.payload,
      };

    case TYPES.SET_AGE:
      return {
        ...state,
        age: action.payload,
      };

    case TYPES.SET_REL_STATUS:
      return {
        ...state,
        relStatus: action.payload,
      };

    case TYPES.SET_PALM_REPORT:
      return {
        ...state,
        palmReport: action.payload,
      }

    case TYPES.SET_HAND_IMG:
      return {
        ...state,
        handImg: action.payload.img,
        handImgDims: { width: action.payload.width, height: action.payload.height, ratio: action.payload.ratio, },
        handKeyPoints: action.payload.keypoints,
        handLines: action.payload.lines,
      };

    case TYPES.SET_BIRTH_DATE:
      return {
        ...state,
        birthDate: action.payload,
      };

    case TYPES.SET_PARTNER_BIRTH_DATE:
      return {
        ...state,
        partnerBirthDate: action.payload,
      };

    case TYPES.SET_CRUSH_ZODIAC:
      return {
        ...state,
        crushZodiac: action.payload,
      };

    case TYPES.SET_BIRTH_TIME:
      return {
        ...state,
        birthTime: action.payload,
      };

    case TYPES.SET_BIRTH_PLACE:
      return {
        ...state,
        birthPlace: action.payload,
      };

    case TYPES.SET_COLOR:
      return {
        ...state,
        color: action.payload,
      };

    case TYPES.SET_ELEMENT:
      return {
        ...state,
        element: action.payload,
      };

    case TYPES.SET_FUTURE_GOALS:
      return {
        ...state,
        futureGoals: action.payload,
      };

    case TYPES.SET_CRITICAL_DIRECTION:
      return {
        ...state,
        direction: action.payload,
        goals: state.direction === action.payload ? state.goals : initialState.goals,
      };

    case TYPES.SET_DESIRABLE_GOALS:
      return {
        ...state,
        goals: action.payload,
      };

    case TYPES.SET_IS_UNSUBSCRIBER:
      return {
        ...state,
        is_unsubscriber: action.payload,
      };

    case TYPES.SET_LANG:
      return {
        ...state,
        lang: action.payload
      };

    default:
      return state;
  }
};

reducer.blacklist = ['authorized', 'linkAuthResult'];

export default reducer;

export const randomPrediction = () => {
  return Math.round(65 + (95 - 65) * Math.random());
}
