import { PROJECTS_TYPES } from 'core/constants/general';
import { parseUrlParams } from 'core/utils/url-sync';

import { render } from './render';

parseUrlParams();

let pType = window.location.pathname.split('/')[1];

let promiseApp;

switch (pType) {
  case PROJECTS_TYPES.TERMINATE:
    promiseApp = import('./screens/terminate').then(r => r.default);
    break;
  case PROJECTS_TYPES.MANAGE:
    promiseApp = import('./screens/manage').then(r => r.default);
    break;
  case 'quiz-book':
    promiseApp = import('./screens/quiz/book').then(r => r.default);
    break;
  case 'summary-book':
    promiseApp = import('./screens/subscription/book').then(r => r.default);
    break;
  case 'astrologer-gen-link':
    promiseApp = import('./screens/astrologer-gen-link').then(r => r.default);
    break;
  case 'astrology-consultation':
    promiseApp = import('./screens/astrology-consultation').then(r => r.default);
    break;
  default:
    promiseApp = import('./screens/quiz').then(r => r.default);
    break;
}

promiseApp.then(render);
