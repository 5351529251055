/* eslint-disable no-use-before-define */
import _find from 'lodash/find';
import _omit from 'lodash/omit';
import { createAction } from 'redux-actions';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import queryString from 'query-string';
import { createBrowserHistory } from 'history';
import { ThunkAction } from 'redux-thunk';

import duration from 'dayjs/plugin/duration';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { Currency } from 'core/../react-billing/constants';

import DeviceProps from '@magnus/react-native-device-props';
import Mutator from '@magnus/react-native-mutator';

import { t } from '@web-solutions/module-localization';
//@ts-ignore
import Billing from '@web-solutions/module-billing';
import { mappingPricesToProducts, PaymentSystem, ProductInfo } from '@web-solutions/react-billing';
import Analytics from '@web-solutions/module-analytics';

import { stringifyUrlParams } from 'core/utils/url-sync';
import { ROUTES } from 'core/constants/routes';
import { PaddleConfig, Purchase, Subscription, SubscriptionProps } from 'core/interfaces/billing';
import { ProductConfig } from 'core/constants/remote-config';

//@ts-ignore
import { setCompleted } from 'src/store/routing/actions';

import { getProductsIds } from './utils';
import { ProductDetails, selectPaymentSystem } from './selectors';

import * as TYPES from './types';

dayjs.extend(duration);
dayjs.extend(localizedFormat);
dayjs.extend(customParseFormat);

const setPaymentSystem = createAction(TYPES.SET_PAYMENT_SYSTEM, (paymentSystem: PaymentSystem) => ({ paymentSystem }));
export const setPurchase = createAction(TYPES.SET_PURCHASE, (purchase: Purchase) => ({ purchase }));
export const resetPurchase = createAction(TYPES.RESET);
export const setIsSubmitByCard = createAction<boolean>(TYPES.SET_IS_SUBMIT_BY_CARD);
const setLoading = createAction(TYPES.SET_LOADING);
const setPending = createAction(TYPES.SET_PENDING);
const setOrderDetails = createAction(TYPES.SET_ORDER_DETAILS);
const setOrderPending = createAction(TYPES.SET_ORDER_PENDING);
export const setPaddleConfig = createAction<PaddleConfig>(TYPES.SET_PADDLE_CONFIG);
const setProducts = createAction<ProductInfo[]>(TYPES.SET_PRODUCTS);
export const setTrialPrice = createAction(TYPES.SET_TRIAL_PRICE);
const setDiscountEndDate = createAction(TYPES.SET_DISCOUNT_END_DATE);
export const setPostCode = createAction<string>(TYPES.SET_POSTCODE);
export const setInjectedPaymentMethod = createAction<string>(TYPES.SET_INJECTED_PAYMENT_METHOD);
export const setOneTimePurchases = createAction(TYPES.SET_ONE_TIME_PURCHASES);

type Ids = 'recurlyId' | 'solidgateId' | 'paddleId' | undefined

type IdsMap = {
  [key in PaymentSystem]: Ids;
};

const IDS_MAP: IdsMap = {
  [PaymentSystem.RECURLY]: 'recurlyId',
  [PaymentSystem.SOLIDGATE]: 'solidgateId',
  [PaymentSystem.PADDLE]: 'paddleId',
  [PaymentSystem.STRIPE]: undefined,
  [PaymentSystem.PAYPAL]: undefined,
}

let initPromise: Promise<any> | null;

export const init = (): ThunkAction<Promise<any>, any, unknown, any> => async (dispatch, getState) => {
  if (!initPromise) {
    const state = getState();
    const {
      app: { paymentProject: appPaymentProject },
      remoteConfig: { paymentProject: rcPaymentProject, },
      billing: { paddleConfig, },
    } = state;

    const paymentSystem = selectPaymentSystem(state);
    const paymentProject = rcPaymentProject || appPaymentProject;

    dispatch(setLoading(true));

    const p = [
      Billing.init(paymentSystem, paymentProject),
      dispatch(initDiscount()),
      dispatch(initProducts()),
    ];

    const qs = queryString.parse(window.location.search);

    if (qs.success === PaymentSystem.PADDLE) {
      let checkout_id = qs.checkout_id || paddleConfig?.checkoutId;
      let product_id = qs.product_id || paddleConfig?.productId;
      let payment_method = qs.payment_method || paddleConfig?.paymentMethod;
      if (checkout_id) {
        createBrowserHistory()
          .replace(window.location.pathname + '?' + queryString.stringify(_omit(qs, ['success', 'checkout_id', 'product_id', 'payment_method'])));
        p.push(
          dispatch(createCustomer({ paymentSystem: PaymentSystem.PADDLE }))
            .then(() => dispatch(subscribe({
              checkout_id: checkout_id,
              price_id: product_id,
              method: payment_method,
              paymentSystem: PaymentSystem.PADDLE,
            })))
        );
      }
    }

    try {
      const oneTimePurchases = (await Billing.getOneTimePurchases());
      dispatch(setOneTimePurchases(oneTimePurchases));
    } catch (e) {
      console.log('[ERROR GET ONE TIME PURCHASES]:', e);
    }


    if (paymentSystem === PaymentSystem.PADDLE) {
      Promise.all([
        Mutator.getCountryCode(),
        DeviceProps.getIDFM(),
      ])
        .catch(() => ([]))
        .then(([country, idfm]) => {
          dispatch(setPaddleConfig({
            country,
            passthrough: idfm
              ? JSON.stringify({
                idfm,
                payment_system_project: paymentProject,
              })
              : undefined,
          }));
        });
    }

    initPromise = Promise.all(p)
      .catch((error) => {
        console.log('[ERROR INIT BILLING]:', error);
        return false;
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  }

  return initPromise;
};

const initProducts = (): ThunkAction<Promise<void>, any, unknown, any> => async (dispatch, getState) => {
  const state = getState();

  const {
    billing: { trialPrice },
  } = state;

  const paymentSystem = selectPaymentSystem(state);

  let {
    remoteConfig: { products, },
  }: { remoteConfig: { products: ProductDetails[] } } = state;

  products = products.map(p => ({
    ...p,
    id: p[IDS_MAP[paymentSystem || PaymentSystem.STRIPE] || 'id'] as string || p.id,
  }));

  let preparedProducts = await dispatch(fetchProducts(products));

  if (trialPrice) {
    const trialProducts = preparedProducts.filter(product => product.trial_price_amount === `${trialPrice}`);
    if (trialProducts.length) {
      preparedProducts = trialProducts;
    }
  }

  dispatch(setProducts(preparedProducts));
  dispatch(initOrders());
};

export const getOneTimePurchases = (): ThunkAction<Promise<void>, any, unknown, any> => async (dispatch) => {
  const oneTimePurchases = (await Billing.getOneTimePurchases());
  dispatch(setOneTimePurchases(oneTimePurchases));
}

export const fetchProducts = (products: ProductConfig[]): ThunkAction<Promise<ProductInfo[]>, any, unknown, any> =>
  async (dispatch, getState) => {
    const state = getState();

    const {
      remoteConfig: { productsDetails },
    } = state;

    const paymentSystem = selectPaymentSystem(state);

    const prices =
      paymentSystem === PaymentSystem.SOLIDGATE
        ? productsDetails
        : await Billing.getPrices(getProductsIds(products), { paymentSystem });

    return mappingPricesToProducts(products, prices);
  };

export const initOrders = (): ThunkAction<Promise<void>, any, unknown, any> => async (dispatch, getState) => {
  const state = getState();

  const {
    billing: { products },
  }: { billing: { products: ProductDetails[] } } = state;

  const paymentSystem = selectPaymentSystem(state);

  const pricesIds = getProductsIds(products);

  if (paymentSystem === PaymentSystem.SOLIDGATE) {
    dispatch(
      createOrder({
        productId: (products.find((product) => product.default) || products[0])?.id,
        products: pricesIds,
        paymentSystem: PaymentSystem.SOLIDGATE,
      }),
    );
  } else if (paymentSystem === PaymentSystem.PADDLE) {
    dispatch(
      createOrder({
        productId: (products.find((product) => product.default) || products[0])?.solidgateId || '',
        products: products.map(p => p.solidgateId).filter(i => !!i) as string[],
        paymentSystem: PaymentSystem.SOLIDGATE,
      }),
    );
  }
}

const initDiscount = (): ThunkAction<Promise<void>, any, unknown, any> => async (dispatch, getState) => {
  const {
    remoteConfig: { discountTime: time },
    billing: { discountEndDate },
  } = getState();

  if (time) {
    if (!discountEndDate) {
      dispatch(setDiscountEndDate(dayjs().add(time, 'second')));
    }
  } else {
    if (discountEndDate) {
      dispatch(setDiscountEndDate(null));
    }
  }
};

export const createCustomer =
  ({ email, paymentSystem }: { email?: string, paymentSystem?: PaymentSystem }): ThunkAction<Promise<void>, any, unknown, any> =>
    async (dispatch, getState) => {
      try {
        const { profile } = getState();
        // Get email from form, otherwise get it from profile. Customer created from paypal won't have email
        email = email || profile.email;
        if (email) {
          Analytics.setUserProperty('email', email);
        }
        dispatch(setPending(true));
        return await Billing.createCustomer({ email, paymentSystem });
      } catch (error) {
        console.log('[ERROR ASSIGN CUSTOMER]', error);
        throw error;
      } finally {
        dispatch(setPending(false));
      }
    };

const createOrder =
  ({ productId, products, paymentSystem }:
    { productId: string, products?: string[], paymentSystem?: PaymentSystem }): ThunkAction<Promise<void>, any, unknown, any> =>
    async (dispatch) => {
      const idfm = await DeviceProps.getIDFM();

      try {
        dispatch(setOrderDetails(null));
        dispatch(setOrderPending(true));
        await dispatch(createCustomer({ paymentSystem }));
        const orderDetails = await Billing.createOrder({
          productId,
          orderDescription: idfm,
          products,
          paymentSystem,
        });
        orderDetails.productId = productId;
        dispatch(setOrderDetails(orderDetails));
      } catch (error) {
        console.log('[ERROR CREATE ORDER]', error);
        throw error;
      } finally {
        dispatch(setOrderPending(false));
      }
    };

interface CreateCheckout {
  productId: string,
  checkoutId: string,
  paymentMethod: string,
  paymentSystem: PaymentSystem
}

export const createCheckout =
  ({ productId, checkoutId, paymentMethod, paymentSystem }: CreateCheckout): ThunkAction<Promise<void>, any, unknown, any> =>
    async (dispatch, getState) => {
      try {
        const p = queryString.parse(window.location.search);
        const successUrl = queryString.stringifyUrl({
          url: window.location.origin + window.location.pathname,
          query: {
            ...p,
            success: PaymentSystem.PADDLE,
          },
        });

        await Billing.createCheckout({
          productId,
          checkoutId,
          paymentMethod,
          successUrl,
          paymentSystem,
        });
      } catch (error) {
        console.log('[ERROR CREATE CHECKOUT]', error);
        throw error;
      }
    };

export const handleSuccessPurchase = (subscription: Subscription): ThunkAction<Promise<Subscription>, any, unknown, any> => async (dispatch, getState) => {
  const { transaction_id, price_id, amount, currency, method, } = subscription;

  const state = getState();

  const {
    billing: { products },
    profile: { email },
  } = state;

  const paymentSystem = selectPaymentSystem(state);

  const p = _find(products, { id: price_id }) || _find(products, { paypalPlanId: price_id });

  if (subscription.email || email || subscription.first_name || subscription.last_name) {
    Analytics.trackEvent('user', 'info', {
      email: subscription.email || email || undefined,
      first_name: subscription.first_name || undefined,
      last_name: subscription.last_name || undefined,
    });
  }

  Analytics.trackPurchaseEvent({
    transactionId: transaction_id,
    productId: price_id,
    revenue: +p?.trialPeriodPriceValue || +p?.amount || +amount,
    currency,
    method,
    email,
    paymentSystem: subscription.paymentSystem || paymentSystem,
  });

  dispatch(setPurchase({ ...subscription, isTrial: p?.isTrial, product: p }));

  stringifyUrlParams({ purchased: null });

  return subscription;
};

export const handleErrorPurchase = (error: any, { noToast }: { noToast: boolean | undefined } = { noToast: false }): ThunkAction<void, any, unknown, any> => (dispatch, getState) => {
  console.warn(error);
  const {
    remoteConfig: { isCustomDeclinedPaymentMessages }
  } = getState();

  if (!error?.data?.three_d_secure_action_token_id) {
    let customMessage;
    if (isCustomDeclinedPaymentMessages && error?.paymentSystem === PaymentSystem.SOLIDGATE) {
      customMessage = t(`core.solidgate_payment_errors`, { returnObjects: true })[error?.code];
    }

    if (!noToast) {
      toast(customMessage || error?.message || 'Something went wrong', { type: 'error', autoClose: customMessage ? 7500 : 5000 });
    }

    Analytics.trackEvent('ecommerce', 'error', {
      message: error?.message,
      code: error?.code,
      paymentSystem: error?.paymentSystem,
      method: error?.method,
    });
  }
};

export const subscribe = (formData: SubscriptionProps): ThunkAction<Promise<Subscription>, any, unknown, any> => async (dispatch, getState) => {
  const {
    profile: { email },
  } = getState();

  try {
    dispatch(setPending(true));

    // If we have no email in form, then get it from profile.
    if (!formData.email && email) {
      formData.email = email;
    }

    if (formData.email) {
      Analytics.setUserProperty('email', formData.email);
    }

    const subscriptionRes = await Billing.subscribe(formData);

    const subscriptionDetails = {
      ...formData,
      ...subscriptionRes,
    };

    return dispatch(handleSuccessPurchase(subscriptionDetails));
  } catch (error: any) {
    const err = error || {};
    err.paymentSystem = formData.paymentSystem || err.paymentSystem;
    throw error;
  } finally {
    dispatch(setPending(false));
  }
};

export const checkActiveSubscription = (): ThunkAction<Promise<[] | undefined>, any, unknown, any> => async (dispatch, getState) => {
  try {
    const { billing: { purchase } } = getState();

    if (purchase) {
      const { data } = await Billing.getSubscriptions();

      if (data) {
        const isActiveSubscription = data.some((subscription: any) => subscription?.active);

        if (!isActiveSubscription) {
          window.localStorage.clear();
          dispatch(setCompleted(false));
          const p = queryString.parse(window.location.search);
          delete p.completed;
          delete p.purchased;
          createBrowserHistory().replace(window.location.pathname + '?' + queryString.stringify(p));
        }
        return data;
      }
    }
  } catch (error) {
    console.log('[ERROR GET SUBSCRIPTIONS]', error);
  }
};

export const changePlan = (productId: string, subscriptionId: string, isNewSubscription: boolean): ThunkAction<Promise<any>, any, unknown, any> => async (dispatch, getState) => {
  dispatch(setPending(true));
  try {
    const url = window.location.origin;
    const successUrl = `${url}${ROUTES.VERIFY_3DS_SUCCESS}`;
    const failUrl = `${url}${ROUTES.VERIFY_3DS_FAIL}`;

    const r = ((await Billing.changePlan({ subscriptionId, productId, isNewSubscription, successUrl, failUrl })) || {});

    console.log(r);

    return r;
  } finally {
    dispatch(setPending(false));
  }
}

export const switchToReservePaymentSystem = (): ThunkAction<Promise<void>, any, unknown, any> => async (dispatch, getState) => {
  const state = getState();
  const paymentSystem = selectPaymentSystem(state);
  const { paymentSystemReserve } = state.remoteConfig;

  if (paymentSystemReserve && paymentSystem !== paymentSystemReserve) {
    dispatch(setPaymentSystem(paymentSystemReserve));
    initPromise = null;
    dispatch(init());
  }
}

export const createOneClickPayment = ({ amount, currency, productCode, trigger = '' }:
  { amount: number, currency: Currency, productCode: string, trigger?: string }): ThunkAction<Promise<void>, any, unknown, any> => async () => {
    const url = window.location.origin;
    const successUrl = `${url}${ROUTES.VERIFY_3DS_SUCCESS}`;
    const failUrl = `${url}${ROUTES.VERIFY_3DS_FAIL}`;
    const description = await DeviceProps.getIDFM();

    return await Billing.createOneClickPayment({
      amount,
      currency,
      productCode,
      successUrl,
      failUrl,
      trigger,
      description
    });
  }

export const giveProduct = (productId: string): ThunkAction<Promise<void>, any, unknown, any> => async () => {
  return Billing.giveProduct(productId);
}
