import { LinkTarget } from '@web-solutions/module-attribution';
import { Currency } from '@web-solutions/react-billing/constants';

import { INITIAL_CONFIG as CORE_INITIAL_CONFIG, ProductConfig, } from 'core/constants/remote-config';
import type { LString, RemoteConfig as CoreRemoteConfig, } from 'core/constants/remote-config';
import { REDIRECT_TO_CHROME } from 'core/utils/redirect-android';

import { QuizNamesValues } from './screens';

export enum SummarySection {
  HEADER = 'header',
  RELSPIN_HEADER = 'relspin_header',
  EXPERTS = 'experts',
  PERSONALISED_PLAN = 'personalised_plan',
  DOWNLOADED = 'downloaded',
  FEEDBACK_LIST = 'feedback_list',
  FEEDBACK_SLIDER = 'feedback_slider',
  REVIEWS = 'reviews',
  FOOTNOTE = 'footnote',
  MONEY_BACK = 'moneyback',
  PLAN_READY = 'plan_ready',
  PREDICT = 'predict',
  HANDS = 'hands',
  PR_OVERVIEW = 'pr_overview',
  ZODIAC_COMPATIBILITY = 'zodiac_compatibility',
  TAROT_PREDICTIONS = 'tarot_predictions',
  GUIDE = 'guide',
  BEST_MATCHES = 'best_matches',
  COMPATIBILITY_REPORT = 'compatibility_report',
}

export enum SuccessPageType {
  IMG = 'img',
  TEXT = 'text',
  BY_EMAIL = 'email',
  GUIDE = 'guide',
}

export enum ModeSocialAuth {
  DISABLED = 'disabled',
  ABOVE = 'above',
  BELOW = 'below',
}

export enum OfferProductId {
  ANALYSIS = 'analysis',
  GUIDE = 'guide',
  ULTRA = 'ultra',
}

const defaultTrials: DefaultTrials = [
  {
    price: 1,
    isDefault: false
  },
  {
    price: 5,
    isDefault: false
  },
  {
    price: 9,
    isDefault: false
  },
  {
    price: 12.2,
    isDefault: true
  }
]

type Trial = {
  price: number,
  isDefault: boolean
}

type DefaultTrials = Trial[];

export type ReportViewType = 'WITH_IMAGE' | 'NO_IMAGE'

export type OfferProductTypes = {
  title: string,
  subTitle: string,
  notActivePrice: string,
  activePrice: string,
  discount: string,
  id: OfferProductId,
  price: number,
  currency: Currency,
}

export interface RemoteConfig extends CoreRemoteConfig {
  trials: DefaultTrials,

  presummaryStack: Array<SummarySection>,
  presummaryTopButtonAfterBlockType: SummarySection[],

  landingStack: Array<SummarySection | 'plans' | 'reserved_price'>,
  landingTopButtonAfterBlockType: SummarySection[],
  isPalmDisclaimerVisible: boolean,
  asbPlans: {
    title: LString,
    buttonText: LString,
    backEnabled: boolean,
  },

  summaryHeaderTitle: LString,
  isBlurOnCompatibilityReportSection: false,
  emailPageTitle: LString,
  isDisclaimer: boolean,
  welcomePage: {
    title: LString,
    subtitle: LString,
    button_title: LString,
    isSignInAvailable: boolean,
  },
  magicDurationLoad: number,
  reportLoaderAutoRedirect: boolean,
  palmSkipNextScreenName: 'QUIZ_REPORT_LOADER' | 'QUIZ_MAGIC' | '',
  flow: QuizNamesValues,
  afterPurchaseFlow: Array<'GUIDES' | 'TRIAL_PLAN_CHANGE' | 'COMPATIBILITY' | 'BIRTH_CHART'>,
  autocompleteEmail: boolean,
  focusEmail: boolean,
  cameraInitTimeout: number,
  cameraMaxRatio: number,
  palmLinesStrokeWidth: number,
  palmLinesPointsAmount: number,
  isPalmReadingUploadPhotoAvailable: boolean,
  isPalmReadingBackAvailable: boolean,
  isPalmReadingApproveAvailable: boolean,
  successPageType: SuccessPageType,
  redirectToChrome: REDIRECT_TO_CHROME,

  modeSocialAuth: ModeSocialAuth,
  astrologyConsultation: {
    buttonTitle: LString,
    buttonPrice: string,
    discount: LString,
    textLineThrough: LString,
    priceText: LString,
    price: number,
    currency: Currency,
  },
  mailingSubscriptionInitial: boolean,
  trialChangeOffer: {
    priceFormat: 'week' | 'day',
    product: ProductConfig,
    currency: Currency,
    refundPrice: number,
  },
  isOneClickFootnote: boolean,
  isDownloadPdfInBrowser: boolean,
  pdfOffer: {
    title: LString,
    text: LString,
    button: LString,
    activeOfferId: OfferProductId | null,
    successModalEnabled: boolean,
    autoRedirectEnabled: boolean,
    products: OfferProductTypes[],
  },
  compatibilityOffer: {
    title: LString,
    text: LString,
    button: LString,
    product: Omit<OfferProductTypes, 'id'>,
    successModalEnabled: boolean,
    autoRedirectEnabled: boolean,
    view: ReportViewType,
  },
  birthChartOffer: {
    title: LString,
    text: LString,
    button: LString,
    product: Omit<OfferProductTypes, 'id'>,
    successModalEnabled: boolean,
    autoRedirectEnabled: boolean,
    view: ReportViewType,
  },
  secondPdfOffer: {
    title: string,
    text: string,
    button: string,
    enabled: boolean,
    activeOfferId: OfferProductId | null,
    products: OfferProductTypes[]
  }

  redirectTerminateToManage: boolean,
}

export const INITIAL_CONFIG: RemoteConfig = {
  ...CORE_INITIAL_CONFIG,

  trials: defaultTrials,
  isPalmDisclaimerVisible: false,
  presummaryStack: [],
  presummaryTopButtonAfterBlockType: [],

  asbPlans: {
    title: '',
    buttonText: '',
    backEnabled: true,
  },

  landingStack: [],
  landingTopButtonAfterBlockType: [],
  summaryHeaderTitle: { en: '' },
  isBlurOnCompatibilityReportSection: false,

  emailPageTitle: { en: '' },
  isDisclaimer: false,
  welcomePage: {
    title: '',
    subtitle: '',
    button_title: '',
    isSignInAvailable: false,
  },
  afterPurchaseFlow: ['GUIDES', 'TRIAL_PLAN_CHANGE', 'COMPATIBILITY', 'BIRTH_CHART'],
  magicDurationLoad: 8000,
  flow: [],
  reportLoaderAutoRedirect: true,
  palmSkipNextScreenName: '',
  autocompleteEmail: false,
  focusEmail: false,

  cameraInitTimeout: 4000,
  cameraMaxRatio: 2,
  palmLinesStrokeWidth: 4,
  palmLinesPointsAmount: 10,
  isPalmReadingUploadPhotoAvailable: false,
  isPalmReadingBackAvailable: false,
  isPalmReadingApproveAvailable: false,

  successPageType: SuccessPageType.TEXT,
  linkTarget: LinkTarget.WEB,

  redirectToChrome: REDIRECT_TO_CHROME.DISABLED,

  modeSocialAuth: ModeSocialAuth.ABOVE,

  mailingSubscriptionInitial: false,

  trialChangeOffer: {
    priceFormat: "week",
    product: {
      id: "23f463fa-16e1-4d3a-8b7a-4881315d75c3",
      solidgateId: "23f463fa-16e1-4d3a-8b7a-4881315d75c3"
    },
    currency: 'USD',
    refundPrice: 1,
  },
  isOneClickFootnote: true,
  isDownloadPdfInBrowser: true,
  compatibilityOffer: {
    title: '',
    text: '',
    button: '',
    product: {
      title: '',
      subTitle: '',
      notActivePrice: '',
      activePrice: '',
      discount: '',
      price: 1,
      currency: 'USD',
    },
    successModalEnabled: true,
    autoRedirectEnabled: false,
    view: "WITH_IMAGE",
  },
  birthChartOffer: {
    title: '',
    text: '',
    button: '',
    product: {
      title: '',
      subTitle: '',
      notActivePrice: '',
      activePrice: '',
      discount: '',
      price: 1,
      currency: 'USD',
    },
    successModalEnabled: true,
    autoRedirectEnabled: false,
    view: "WITH_IMAGE",
  },
  pdfOffer: {
    title: '',
    text: '',
    button: '',
    activeOfferId: OfferProductId.ANALYSIS,
    successModalEnabled: true,
    autoRedirectEnabled: false,
    products: [],
  },
  secondPdfOffer: {
    title: '',
    text: '',
    button: '',
    activeOfferId: null,
    enabled: false,
    products: []
  },
  astrologyConsultation: {
    buttonTitle: 'Continue chat',
    buttonPrice: '$49.99',
    discount: '<s>$89.99</s> <gray> $49.99 </gray> <discount> 44% OFF </discount>',
    textLineThrough: '$89.99',
    priceText: '$49.99',
    price: 49.99,
    currency: 'USD',
  },

  redirectTerminateToManage: false,
};

export type FullConfig = Partial<RemoteConfig>;
