import _findIndex from 'lodash/findIndex';
import _omit from 'lodash/omit';
import { createBrowserHistory } from 'history';
import queryString from 'query-string';
import { createAction } from 'redux-actions';

import Analytics from '@web-solutions/module-analytics';

import { setPurchase } from 'core/store/billing/actions';
import { remoteConfigSelector } from 'core/store/remote-config/selectors';
import { REDIRECT_TO_CHROME, redirectToChrome } from 'core/utils/redirect-android';

import { SUBSCRIPTION_NAMES } from 'src/constants/screens';
import { ROUTES, SUBSCRIPTION, SCREENS_WITH_AUTH } from 'src/constants/routes';

import { processEmail } from '../profile/actions';

import { SET_COMPLETED, SET_START_ROUTE, SET_STEP } from './types';

import { selectFlow } from './selectors';

export const setStep = createAction(SET_STEP);
export const setStartRoute = createAction(SET_START_ROUTE);
export const setCompleted = createAction(SET_COMPLETED);

export const init = ({ linkAuthResult } = {}) => (dispatch, getState) => {
  const { redirectToChrome: rtc } = remoteConfigSelector(getState());
  const {
    billing: { purchase }
  } = getState();

  const flow = selectFlow(getState());

  if (rtc === REDIRECT_TO_CHROME.ENABLED) {
    Analytics.trackEvent('android', 'redirect', { mode: rtc });
    redirectToChrome();
  }

  const params = queryString.parse(window.location.search);

  if (params.completed !== undefined) {
    dispatch(setCompleted(true));
  }

  if (params.purchased !== undefined && !purchase) {
    dispatch(setPurchase({}));
  }

  if (params.email) {
    dispatch(processEmail(params.email));
  }

  const {
    routing: { isCompleted }
  } = getState();


  if (isCompleted) {
    const startRoute = dispatch(getRouteAfterFlow());
    dispatch(setStartRoute(startRoute));
  } else {
    if (linkAuthResult || params.auth === null) {
      createBrowserHistory()
        .replace(queryString.stringifyUrl({
          url: window.location.pathname,
          query: _omit(params, ['auth']),
        }));
      const index = _findIndex(flow, r => SCREENS_WITH_AUTH.includes(r));
      dispatch(setStep(index));
    }

    const startRoute = ROUTES[flow[0].toUpperCase()];
    dispatch(setStartRoute(startRoute));
  }

  return Promise.resolve();
};

export const getRouteAfterFlow = () => (dispatch, getState) => {
  const {
    remoteConfig: { presummaryStack },
    billing: { purchased },
  } = getState();
  if (purchased) {
    return SUBSCRIPTION[SUBSCRIPTION_NAMES.SUCCESS];
  } else if (presummaryStack?.length) {
    return SUBSCRIPTION[SUBSCRIPTION_NAMES.PRESUMMARY];
  } else {
    return SUBSCRIPTION[SUBSCRIPTION_NAMES.MAIN];
  }
};
