import React, { useMemo } from 'react';
import classNames from 'classnames';
// @ts-ignore
import Modal from 'react-modal';

import { t } from '@web-solutions/module-localization';

import { Title, Text, LinkButton } from 'core/ui-elements';
import { Button } from 'core/ui-elements';
import { useRemoteConfig } from 'core/hooks/use-remote-config';
import { Theme } from 'core/constants/theme';
import { useThemeContext } from 'core/hooks/use-theme-context';

import { ReactComponent as IconCancel } from './icons/cancel.svg';
import { IMAGES } from './images';

import classes from './style.module.scss';

const images = {
  'card': IMAGES.CARD_BG,
  'billing': IMAGES.BILLING_BG,
}

const images_dark = {
  'card': IMAGES.DARK_CARD_BG,
  'billing': IMAGES.DARK_BILLING_BG,
}

interface Props {
  visible: boolean;
  onClose: () => void;
  onSubmit: () => void;
  statusCode?: string | number,
  withStatusCode?: boolean,
  buttonTitle?: string,
  tKey?: string,
  onSkipClick?: () => void;
}

const ErrorPopup: React.FC<Props> = ({
  visible,
  onClose,
  onSubmit,
  buttonTitle,
  statusCode,
  tKey = 'core.subscription.error_popup',
  onSkipClick,
}) => {

  const { errorPopup } = useRemoteConfig();
  const { theme } = useThemeContext();
  const isDark = theme === Theme.dark ? true : false;

  const code = useMemo(() => {
    const isInsufficentFunds = statusCode === '3.02' || statusCode === '302' || statusCode === 302 || statusCode === 3.02
    if (statusCode && isInsufficentFunds) {
      return `302`
    } else {
      return errorPopup?.type
    }
  }, [errorPopup?.type, statusCode])

  return (
    <Modal
      isOpen={visible}
      className={classNames(classes.popup, { [classes.dark]: isDark })}
      overlayClassName={classNames(classes.overlay, { [classes.dark]: isDark })}
    >
      <IconCancel onClick={onClose} className={classes.close} />
      <img
        className={classes.img}
        src={isDark ? images_dark[errorPopup?.type].src : images[errorPopup?.type].src}
        srcSet={isDark ? images_dark[errorPopup?.type].srcSet : images[errorPopup?.type].srcSet}
        alt='background'
      />
      <Title className={classes.title}>
        {t(`${tKey}.${code}.title`)}
      </Title>
      <Text className={classes.text}>
        {t(`${tKey}.${code}.text`)}
      </Text>
      <Button
        onClick={onSubmit}
        flare={false}
        iconRight={false}
        className={classes.button}
        title={buttonTitle || t(`${tKey}.${code}.button`)}
      />
      {onSkipClick && <LinkButton className={classes.button} onClick={onSkipClick}>
        {t(`${tKey}.${code}.button_later`)}
      </LinkButton>}
    </Modal>
  )
};

export default ErrorPopup;
