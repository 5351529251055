import _mapValues from 'lodash/mapValues';

import { QUIZ_NAMES, SUBSCRIPTION_NAMES } from './screens';

export const SUBSCRIPTION = {
  [SUBSCRIPTION_NAMES.PRESUMMARY]: '/subscription/presummary',
  [SUBSCRIPTION_NAMES.MAIN]: '/subscription/main',
  [SUBSCRIPTION_NAMES.SUCCESS]: '/subscription/success',
  [SUBSCRIPTION_NAMES.COMPATIBILITY]: '/subscription/improve-reports-compatibility',
  [SUBSCRIPTION_NAMES.BIRTH_CHART]: '/subscription/improve-reports-birth-chart',
  [SUBSCRIPTION_NAMES.GUIDES]: '/subscription/guides',
  [SUBSCRIPTION_NAMES.TRIAL_PLAN_CHANGE]: '/subscription/trial-plan-change',
};

export const QUIZ: { [key: string]: string } = _mapValues(QUIZ_NAMES, (v) => '/' + v.toLowerCase());

export const ROUTES = {
  ...QUIZ,
  ...SUBSCRIPTION,
};

export const SCREENS_WITHOUT_PROGRESSBAR = [
  QUIZ_NAMES.QUIZ_MAGIC,
  QUIZ_NAMES.QUIZ_REPORT_LOADER,
  QUIZ_NAMES.QUIZ_EMAIL,
  QUIZ_NAMES.RELSPIN_EMAIL,
  QUIZ_NAMES.WARMUP_LOADER,
  QUIZ_NAMES.QUIZ_TRIAL,
  QUIZ_NAMES.QUIZ_MAILING,
];

export const SCREENS_WITH_AUTH = [
  QUIZ_NAMES.QUIZ_EMAIL,
  QUIZ_NAMES.RELSPIN_EMAIL,
];

